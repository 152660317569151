<template>
    <div class="inner_pages" id="contact_index">
        <!-- <inner-sidebar /> -->
        <div class="respective_content">
            <div class="contact_page">
                <quote-loader v-if="contactsLoader" />
                <template v-if="isMounted">
                    <launch-pad v-model="lauchPad" :contact="selectedContact" />
                    <profile-component v-model="profile" :contact="selectedContact" :updateSelectedContact="updateSelectedContact" :tab="tab" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))

    export default {
        name: 'Contact Index',

        data () {
            return {
                lauchPad: false,
                profile: false,
                isMounted: false,
                tab: 0,
            };
        },

        components: {
            LaunchPad,
            ProfileComponent,
        },

        watch: {
            lauchPad (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            profile (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');

                    vm.$router.push({ name: 'ContactIndex'});
                }
            },

            selectedContact: {
                handler () {
                    const vm = this;

                    vm.isMounted = true;
                    vm.profile = true;
                },
                deep: true
            },

            photoUpdateLoader (val) {
                if (!val) {
                    const vm = this;

                    vm.fetchContact(vm.$route.params.id);
                    vm.getAllDetails(vm.$route.params.id);
                }
            },

            contactSaveLoader (val) {
                if (!val) {
                    const vm = this;

                    vm.fetchContact(vm.$route.params.id);
                    vm.getAllDetails(vm.$route.params.id);
                }
            },
        },

        computed: mapState({
            contactsLoader: state => state.contactModule.contactsLoader,
            selectedContact: state => state.contactModule.selectedContact,
            getContacts: state => state.contactModule.getContacts,
            photoUpdateLoader: state => state.contactModule.photoUpdateLoader,
            contactSaveLoader: state => state.contactModule.contactSaveLoader,
        }),

        mounted () {
            const vm = this;

            vm.fetchContact(vm.$route.params.id);
            vm.getAllDetails(vm.$route.params.id);

            if (localStorage.getItem('contact_profile_tab')) {
                vm.tab = parseInt(localStorage.getItem('contact_profile_tab'));
            }
        },

        methods: {
            ...mapActions({
                fetchContact: 'contactModule/fetchContact',
                getContactStats: 'contactModule/getContactStats',
                getNotes: 'contactModule/getNotes',
                getContactRewardPoints: 'contactModule/getContactRewardPoints',
                getContactTags: 'contactModule/getContactTags',
            }),

            toggleLaunchPad (contact) {
                const vm = this;

                vm.lauchPad = true;
            },

            updateSelectedContact (contact) {
                const vm = this;

                vm.getContacts(localStorage.getItem('contacts_params') ? JSON.parse(localStorage.getItem('contacts_params')) : {});
                vm.selectedContact = contact;
            },

            getAllDetails (id) {
                const vm   = this;

                let params = {
                    page: 1,
                    per_page: 2,
                    id,
                }

                vm.getContactStats(id);
                vm.getContactTags(id);
                vm.getContactRewardPoints(id);

                delete params.id;
                params.contact_id = id;

                vm.getNotes(params);
            },
        },
    }
</script>

<style scoped>
    :deep(.contact_page .quote_wpr) {
        min-height: 75vh;
        justify-content: center;
    }
</style>