<template>
    <div class="inner_pages" id="contact_index">
        <div class="respective_content">
            <div class="contact_page">
                <div class="featured_items">
                    <ul>
                        <li class="get_started" v-if="welcomeCoachSection">
                            <div class="feature_card">
                                <div class="left">
                                    <h2>Welcome Coach!</h2>
                                    <h5>Ready to start? Click the house icon, then "Set up my account" from the home screen. All set? Explore our menu for more options!</h5>
                                    <router-link :to="{ path: '/home' }" class="redirect_btn">Get Started<i class="fas fa-long-arrow-alt-right"></i></router-link>
                                </div>
                                <div class="right">
                                    <label for="welcome-coach-section" class="switch_option capsule_btn p-0 d-none">
                                        <input type="checkbox" id="welcome-coach-section" v-model="welcomeCoachSection" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <img src="@/assets/images/launch.svg">
                                </div>
                            </div>
                        </li>
                        <li class="anonymous_card" v-if="thrive_checkout">
                            <div class="feature_card">
                                <div class="left">
                                    <h2>Need a Program?</h2>
                                    <h5>Explore Thrive Marketplace™ for a variety of instant deployment-ready Thrive content. Includes Canva templates. Just import, edit, and go!</h5>
                                    <a class="redirect_btn" @click="marketplacePopup = true;">Visit Marketplace<i
                                        class="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                                <div class="right">
                                    <label for="thrive_checkout" class="switch_option capsule_btn p-0 d-none">
                                        <input type="checkbox" id="thrive_checkout" v-model="thrive_checkout" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <img src="@/assets/images/marketplace_store.svg">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="panel_wpr">
                    <button class="show_hide" @click="toggleWidget">
                        {{ widget ? "Hide" : "Show" }} Stats
                    </button>
                    <Transition duration="550" name="nested">
                        <div class="actions_widgets outer" v-if="widget">
                            <div class="left_sectn">
                                <chart-widget />
                            </div>
                            <div class="right_sectn">
                                <stats-widget />
                            </div>
                        </div>
                    </Transition>
                </div>
                <contact-header :clear-filter="handleFilterClear" :filter-func="toggleFilter" v-model="filter" :has-filter="params.filters" :filter-aply="applyFilter" ref="contact-header-component" :refresh-contacts="refreshContacts" :handle-contact-search="handleSearch" />
                <div class="table_spacer">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left w-100">
                                <li>
                                    <label for="check" class="checkbox">
                                        <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>{{ selectedContacts.length ? `${selectedContacts.length} Selected` : `Select All ${contactList.length}`}}</h5>
                                    </label>
                                </li>
                                <li class="optionDrops bulk_action" @click.self="toggleBulkAction" v-if="selectedContacts.length" v-click-outside="closeBulkOption">
                                    Bulk Action <i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr megamenu" :class="{ active: bulkAction }">
                                        <p v-if="user.email_status == 0 || user.mailgun_email == ''" style="padding: 8px 14px">
                                            You can access these features but your email sending is currently disabled. Please contact support to restore your email sending capabilities.
                                        </p>
                                            <ul>
                                            <template v-for="(action, a) of bulkActions" :key="a">
                                                <li @click="triggerBulkAction(action.action)" :class="{'danger': action.title == 'Bulk Delete'}" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && !action.hasSubMenu">
                                                    {{ action.title }}
                                                </li>
                                                <li @click="triggerBulkAction(action.action)" v-if="!action.hasCan && !action.hasSubMenu">
                                                    {{ action.title }}
                                                </li>
                                                <li @click="triggerSubmenu($event)" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && action.hasSubMenu">
                                                    {{ action.title }} <i class="fas fa-chevron-down"></i>
                                                    <div class="sub_dropdown">
                                                        <ul>
                                                            <template v-for="(submenu, s) in action.submenus" :key="s">
                                                                <li @click="triggerBulkAction(submenu.action)" v-if="submenu.hasCan && companyUserCan(submenu.can.action, submenu.can.module)">{{ submenu.title }}</li>
                                                                <li @click="triggerBulkAction(submenu.action)" v-if="!submenu.hasCan">{{ submenu.title }}</li>
                                                            </template>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </li>
                                <li class="optionDrops contacts-tabs ml-auto" @click="toggleStatusDropdown" v-click-outside="closeStatusDropdown">
                                    <label v-tooltip="`Choose a segment to view`" position="left">{{ selectedTab }}</label><i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="statusList ? 'active' : ''">
                                        <ul class="scroll_bar" v-if="statusTabsLoader">
                                            <li>Loading...</li>
                                        </ul>
                                        <ul class="scroll_bar" @scroll="scrollFunc($event)" v-else>
                                            <li class="dropdown-header">Leads</li>
                                            <li v-for="(tab, t) of statuses.leads" :key="`leads-${t}`" @click="switchTab(tab)">{{ tab.title }}</li>
                                            <li class="dropdown-header">Trials</li>
                                            <li v-for="(tab, t) of statuses.trials" :key="`trials-${t}`" @click="switchTab(tab)">{{ tab.title }}</li>
                                            <li class="dropdown-header">Clients</li>
                                            <li v-for="(tab, t) of statuses.clients" :key="`clients-${t}`" @click="switchTab(tab)">{{ tab.title }}</li>
                                            <li class="dropdown-header">Staff</li>
                                            <li v-for="(tab, t) of statuses.staff" :key="`staff-${t}`" @click="switchTab(tab)">{{ tab.title }}</li>
                                            <li class="dropdown-header">Lost</li>
                                            <li v-for="(tab, t) of statuses.lost" :key="`lost-${t}`" @click="switchTab(tab)">{{ tab.title }}</li>
                                        </ul>
                                        <span :class="scrollStatus ? 'scroll_indicator swing' : 'scroll_indicator'" @click="scrolldown($event, 'scroll_bar')"><i class="fas fa-long-arrow-alt-up"></i></span>
                                    </div>
                                </li>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()">
                                    Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="contact-per-page-filter" />
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="contactsLoader"><quote-loader /></div>
                        <div class="scroll_table">
                            <table class="show_header contact-table" v-show="!contactsLoader">
                                <thead v-if="tablePreferences.table_header">
                                    <tr>
                                        <th></th>
                                        <th v-if="tablePreferences.photo  || tablePreferences.first_name || tablePreferences.last_name" class="name sort-contact" @click="handleSortContacts('name')">Name<i :class="`fas fa-long-arrow-alt-${params.order == 'desc' ? 'up' : 'down'}`"></i></th>
                                        <th v-if="tablePreferences.date_created" class="sort-contact" @click="handleSortContacts('joined')">Joined<i :class="`fas fa-long-arrow-alt-${params.order == 'desc' ? 'up' : 'down'}`"></i></th>
                                        <th v-if="tablePreferences.email">Email</th>
                                        <th v-if="tablePreferences.number">Phone</th>
                                        <th v-if="tablePreferences.status">Segment</th>
                                        <th v-if="tablePreferences.last_action">Last Action</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="contacts.total || contactsLoader" :class="{'rounded_corner' : !tablePreferences.table_header}">
                                    <tr v-for="(contact, c) of contactList" :key="c">
                                        <td>
                                            <label :for="`contact-${contact.id}`" class="checkbox" :class="{'show' : selectedContacts.includes(contact.id) }">
                                                <input type="checkbox" :id="`contact-${contact.id}`" :value="contact.id" v-model="selectedContacts" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </td>
                                        <td v-if="tablePreferences.photo || tablePreferences.first_name || tablePreferences.last_name">
                                            <div class="user_wpr" v-click-outside="closeTemperatureOption">
                                                <div class="user_img" v-if="tablePreferences.photo">
                                                    <img v-if="tablePreferences.photo" :src="contact.photo" alt="" @click.self="toggleProfile(contact)">
                                                    <span v-if="tablePreferences.temperature" :class="`temp ${contact.temperature ? contact.temperature.toLowerCase() : 'warm'}`" @click="temperatureOption($event)">
                                                        <ul class="row_option">
                                                            <li @click="changeTemperature(contact.id, 'cold')">Cold</li>
                                                            <li @click="changeTemperature(contact.id, 'warm')">Warm</li>
                                                            <li @click="changeTemperature(contact.id, 'hot')">Hot</li>
                                                        </ul>
                                                    </span>
                                                    <svg v-if="tablePreferences.starred" class="tag" :class="{ active: contact.starred }" @click="toggleStarred($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                                                </div>
                                                <h4 @click="toggleProfile(contact)">
                                                    <span class="mr-1" v-if="tablePreferences.first_name">{{ contact.first_name }}</span>
                                                    <span v-if="tablePreferences.last_name">{{ contact.last_name }}</span>
                                                </h4>
                                            </div>
                                        </td>
                                        <td v-if="tablePreferences.date_created"><span>{{ moment(contact.date_created).format('ll') }}</span></td>
                                        <td v-if="tablePreferences.email">
                                            <div class="email_wpr" :class="{ hide: (editEmail && (form.id == contact.id)) }" @click="handleEditSelectedField(contact, 'editEmail')">
                                                <span class="email_add">{{ contact.email }}</span>
                                                <div class="btn_wpr" v-if="contact.valid_email == 0">
                                                    <button class="unconfirm">Invalid Email</button>
                                                </div>
                                                <div class="btn_wpr" v-if="contact.is_confirm == 0">
                                                    <button class="unconfirm">Unconfirmed</button>
                                                </div>
                                            </div>
                                            <div class="ph_field" :class="{ hide: (!editEmail || (editEmail && (form.id != contact.id))) }">
                                                <Form @submit="handleUpdateContact" v-slot="{ errors }" :ref="`contact-edit-form-editEmail-${contact.id}`">
                                                    <div :class="{ 'has-error': errors[`email-${contact.id}`] }">
                                                        <Field autocomplete="off" type="text" :name="`email-${contact.id}`" v-model="form.email" placeholder="Enter email" rules="required|email" label="email" />
                                                    </div>
                                                    <ErrorMessage :name="`email-${contact.id}`" class="text-danger" />

                                                    <button :disabled="updateContactLoader" type="button" class="cross" @click="editEmail = false;"><i class="fas fa-times"></i></button>
                                                    <button :disabled="updateContactLoader" type="button" class="yes" @click="handleUpdateContact('editEmail')">
                                                        <i class="fa fa-spinner fa-spin" v-if="updateContactLoader && form.id == contact.id && updatedField == 'editEmail'"></i>
                                                        <i class="fas fa-check" v-else></i>
                                                    </button>
                                                </Form>
                                            </div>
                                        </td>
                                        <td v-if="tablePreferences.number">
                                            <div class="email_wpr" :class="{ hide: (editNumber && (form.id == contact.id)) }" @click="handleEditSelectedField(contact, 'editNumber')">
                                                <span class="email_add">{{ contact.number }}</span>
                                                <div class="btn_wpr" v-if="contact.number && contact.valid_sms == 0">
                                                    <button class="unconfirm">Invalid Phone</button>
                                                </div>
                                                <div class="btn_wpr" v-else-if="!contact.number">
                                                    <button class="unconfirm">Missing Phone</button>
                                                </div>
                                            </div>
                                            <div class="ph_field" :class="{ hide: (!editNumber || (editNumber && (form.id != contact.id))) }">
                                                <Form @submit="handleUpdateContact" v-slot="{ errors }" :ref="`contact-edit-form-editNumber-${contact.id}`">
                                                    <div :class="{ 'has-error': errors[`number-${contact.id}`] }">
                                                        <Field autocomplete="off" type="text" :name="`number-${contact.id}`" v-model="form.number" placeholder="Enter number" rules="required" label="phone number" />
                                                    </div>
                                                    <ErrorMessage :name="`number-${contact.id}`" class="text-danger" />

                                                    <button :disabled="updateContactLoader" type="button" class="cross" @click="editNumber = false;"><i class="fas fa-times"></i></button>
                                                    <button :disabled="updateContactLoader" type="button" class="yes" @click="handleUpdateContact('editNumber')">
                                                        <i class="fa fa-spinner fa-spin" v-if="updateContactLoader && form.id == contact.id && updatedField == 'editNumber'"></i>
                                                        <i class="fas fa-check" v-else></i>
                                                    </button>
                                                </Form>
                                            </div>
                                        </td>
                                        <td v-if="tablePreferences.status" class="pointer" @click="toggleContactStatus(contact)"><span>{{ contact.status_title ? contact.status_title : '-'  }}</span></td>
                                        <td v-if="tablePreferences.last_action" class="pointer" @click="toggleLastAction(contact)"><span>{{ contact.last_action ? contact.last_action : 'None'  }}</span></td>
                                        <td class="action_icons">
                                            <!-- <a>
                                                <span v-tooltip="`Alert`" position="left"><img src="@/assets/images/alert2.svg"></span>
                                            </a> -->
                                            <a class="access-portal" v-if="contact.has_client_portal" target="_blank" :href="contact.client_portal_access_link">
                                                <span v-tooltip="`Log into your clients portal`" position="left"><img src="@/assets/images/key.svg"></span>
                                            </a>
                                            <a @click="toggleLaunchPad(contact, user)">
                                                <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else :class="{'rounded_corner' : !tablePreferences.table_header}">
                                    <tr>
                                        <td colspan="9" class="px-4 text-center">
                                            <div class="empty_box">
                                                <img src="@/assets/images/empty_state.svg">
                                                <h4>No Records Found</h4>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of {{ contacts.total }}</li>
                        </ul>
                    </div>
                    <div class="pagination mb-5" v-show="!contactsLoader && contacts.total">
                        <pagination v-model="params.page" :pages="contacts.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>

                <div class="modal secondary webinar_modal" v-if="webinarPop" :class="{active : webinarPop}">
                    <div class="modal_container">
                        <div class="circle"><img src="@/assets/images/webinar.svg" alt=""></div>
                        <h5 class="mt-4">Please join us daily at 3 pm Eastern time in the USA for on-demand Thrive coaching with the team. Bring your questions, concerns, and conundrums and we will help you Thrive!</h5>
                        <a href="https://academy.thrivecoach.io/office-hours" target="_blank">Click daily at 3 pm Eastern</a>
                        <button type="button" class="close_btn" @click="webinarPop = false"><i class="fas fa-times"></i></button>
                    </div>
                </div>

                <div class="modal secondary video_player" v-if="tutorial">
                    <div class="modal_container">
                        <div class="modal_header">
                            <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                        </div>
                        <div class="video_wpr">
                            <iframe src="https://www.loom.com/embed/897c20940bb7451ebfa79adaf763bf69?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                        <div class="modal_footer">
                            <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>

                <template v-if="isMounted">
                    <launch-pad v-model="lauchPad" :contact="selectedContact" />
                    <profile-component v-model="profile" :contact="selectedContact" :updateSelectedContact="updateSelectedContact" />
                    <bulk-action-change-gender v-model="genderToggle" :contacts="selectedContacts" />
                    <bulk-action-switch-tab v-model="statusToggle" :contacts="selectedContacts" />
                    <bulk-action-country-code v-model="togglePhoneCountryCodePanel" :contacts="selectedContacts" />
                    <bulk-action-merge-contact v-model="toggleMergeContactsPanel" :contacts="selectedContacts" />
                    <bulk-action-move-contact v-model="toggleAssignContactsPanel" :contacts="selectedContacts" />
                    <bulk-action-celebrate-birthday v-model="toggleContactsBirthdayPanel" :contacts="selectedContacts" />
                    <bulk-action-copy-contact v-model="toggleCopyContactsPanel" :contacts="selectedContacts" />
                    <bulk-action-change-member-id v-model="toggleChangeMemberIdPanel" :contacts="selectedContacts" />
                    <bulk-action-sms v-model="sendSMSToggle" :contacts="selectedContacts" :contacts-data="contacts.data" />
                    <bulk-action-add-tag v-model="addTagsToggle" :contacts="selectedContacts" />
                    <bulk-action-remove-tag v-model="removeTagsToggle" :contacts="selectedContacts" />
                    <bulk-action-send-email v-model="sendEmailToggle" :contacts="selectedContacts" :is-confirmation="confirmationEmail" />
                    <bulk-action-start-pipeline v-model="toggleAddPipelineContactsPanel" :contacts="selectedContacts" />
                    <bulk-action-remove-pipeline v-model="toggleRemovePipelineContactsPanel" :contacts="selectedContacts" />
                    <bulk-action-start-sequence v-model="sendSequenceToggle" :contacts="selectedContacts" />
                    <bulk-action-stop-sequence v-model="stopSequenceToggle" :contacts="selectedContacts" />
                    <bulk-action-start-journey v-model="startJourneyToggle" :contacts="selectedContacts" />
                    <bulk-action-terminate-journey v-model="terminateJourneyToggle" :contacts="selectedContacts" />
                    <bulk-action-send-playbook v-model="assignPlaybookToggle" :contacts="selectedContacts" />
                    <bulk-action-restrict-playbook v-model="restrictPlaybookToggle" :contacts="selectedContacts" />
                    <bulk-action-send-template v-model="sendTemplateToggle" :contacts="selectedContacts" />
                    <bulk-action-send-form v-model="sendFormToggle" :contacts="selectedContacts" />
                    <bulk-action-restrict-form v-model="restrictFormToggle" :contacts="selectedContacts" />
                    <bulk-action-send-page v-model="sendPageToggle" :contacts="selectedContacts" />
                    <bulk-action-restrict-page v-model="restrictPageToggle" :contacts="selectedContacts" />
                    <bulk-action-add-file v-model="addFileToggle" :contacts="selectedContacts" />
                    <bulk-action-start-progress-tracking v-model="toggleStartProgressTracking" :contacts="selectedContacts" />
                    <bulk-action-stop-progress-tracking v-model="toggleStopProgressTracking" :contacts="selectedContacts" />
                    <bulk-action-start-habit-tracking v-model="toggleStartHabitTracking" :contacts="selectedContacts" />
                    <bulk-action-stop-habit-tracking v-model="toggleStopHabitTracking" :contacts="selectedContacts" />
                    <bulk-action-start-task v-model="toggleStartTask" :contacts="selectedContacts" />
                    <bulk-action-stop-task v-model="toggleStopTask" :contacts="selectedContacts" />
                    <bulk-action-send-portal-message v-model="sendPortalMessage" :contacts="selectedContacts" />
                    <last-action v-model="lastAction" :contact="selectedContact" />
                    <bulk-action-switch-tab v-model="contactStatus" :contacts="[selectedContact.id]" :status="selectedContactStatus" />
                    <bulk-action-start-workout v-model="toggleStartWorkout" :contacts="selectedContacts" />
                    <bulk-action-stop-workout v-model="toggleStopWorkout" :contacts="selectedContacts" />
                    <bulk-action-start-workout-plan v-model="toggleStartWorkoutPlan" :contacts="selectedContacts" />
                    <bulk-action-stop-workout-plan v-model="toggleStopWorkoutPlan" :contacts="selectedContacts" />
                </template>
            </div>
        </div>
        <marketplace-component v-model="marketplace"></marketplace-component>
      <PopUpModal v-model="marketplacePopup">
        <div class="popup-container">
          <div class="left">
            <h2>New Thrive Marketplace</h2>
            <p>Coming Soon!</p>
            <button type="button" class="add_btn pointer" @click="marketplacePopup = false">
              Got it!
            </button>
          </div>
          <div class="right">
            <img src="@/assets/images/marketplace_store.svg">
          </div>
        </div>
      </PopUpModal>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

    const ChartWidget = defineAsyncComponent(() => import('@/pages/contact/components/widget/Chart'))
    const StatsWidget = defineAsyncComponent(() => import('@/pages/contact/components/widget/Stats'))
    const ContactHeader = defineAsyncComponent(() => import('@/pages/contact/components/Header'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const BulkActionChangeGender = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeGender'))
    const BulkActionSwitchTab = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))
    const BulkActionCountryCode = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CountryCode'))
    const BulkActionMergeContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MergeContact'))
    const BulkActionMoveContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MoveContact'))
    const BulkActionCelebrateBirthday = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CelebrateBirthday'))
    const BulkActionCopyContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CopyContact'))
    const BulkActionChangeMemberId = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeMemberId'))
    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))
    const BulkActionAddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const BulkActionRemoveTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemoveTag'))
    const BulkActionSendEmail = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendEmail'))
    const BulkActionStartPipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartPipeline'))
    const BulkActionRemovePipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemovePipeline'))
    const BulkActionStartSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartSequence'))
    const BulkActionStopSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopSequence'))
    const BulkActionStartJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartJourney'))
    const BulkActionTerminateJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/TerminateJourney'))
    const BulkActionSendPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPlaybook'))
    const BulkActionRestrictPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPlaybook'))
    const BulkActionSendTemplate = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendTemplate'))
    const BulkActionSendForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendForm'))
    const BulkActionRestrictForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictForm'))
    const BulkActionSendPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPage'))
    const BulkActionRestrictPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPage'))
    const BulkActionAddFile = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddFile'))
    const BulkActionStartProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartProgressTracking'))
    const BulkActionStopProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopProgressTracking'))
    const BulkActionStartHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartHabitTracking'))
    const BulkActionStopHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopHabitTracking'))
    const BulkActionStartTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartTask'))
    const BulkActionStopTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopTask'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const BulkActionSendPortalMessage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPortalMessage'))
    const LastAction = defineAsyncComponent(() => import('@/pages/contact/components/LastAction'))
    const BulkActionStartWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkout'))
    const BulkActionStopWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkout'))
    const BulkActionStartWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkoutPlan'))
    const BulkActionStopWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkoutPlan'))

    const MarketplaceComponent = defineAsyncComponent(() => import('@/components/Marketplace'))
    

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import PopUpModal from '@/components/PopUpModal.vue'

    export default {
        name: 'Contact Index',

        data () {
            return {
                bulkAction: false,
                statusList: false,
                filter: false,
                widget: false,
                editStatusTabs: false,
                selectAll: false,
                isTyping: false,
                selectedContacts: [],
                selectedTab: 'Leads',
                params: {
                    page: 1,
                    per_page: localStorage.getItem('contacts_per_page') ? parseInt(localStorage.getItem('contacts_per_page')) : 5,
                    search: '',
                    order: 'desc',
                    order_by: 'id',
                    type: 'leads',
                },
                selectedContact: {},
                lauchPad: false,
                bulkAlert: false,
                alertInfo: '',
                confirmScreen: false,
                confirmInfo: '',
                genderToggle: false,
                statusToggle: false,
                togglePhoneCountryCodePanel: false,
                toggleMergeContactsPanel: false,
                toggleAssignContactsPanel: false,
                toggleContactsBirthdayPanel: false,
                toggleCopyContactsPanel: false,
                toggleChangeMemberIdPanel: false,
                sendSMSToggle: false,
                addTagsToggle : false,
                removeTagsToggle : false,
                sendEmailToggle : false,
                toggleAddPipelineContactsPanel : false,
                toggleRemovePipelineContactsPanel : false,
                sendSequenceToggle: false,
                stopSequenceToggle: false,
                confirmationEmail: false,
                startJourneyToggle: false,
                terminateJourneyToggle: false,
                assignPlaybookToggle: false,
                restrictPlaybookToggle: false,
                sendTemplateToggle: false,
                sendFormToggle: false,
                restrictFormToggle: false,
                sendPageToggle: false,
                restrictPageToggle: false,
                addFileToggle: false,
                toggleStartProgressTracking: false,
                toggleStopProgressTracking: false,
                toggleStartHabitTracking: false,
                toggleStopHabitTracking: false,
                toggleStartWorkout: false,
                toggleStopWorkout: false,
                toggleStartWorkoutPlan: false,
                toggleStopWorkoutPlan: false,
                toggleStartTask: false,
                toggleStopTask: false,
                profile: false,
                scrollStatus: true,
                moment,
                sendPortalMessage:false,
                form: {},
                contactList: [],
                updatedField: '',
                editEmail: false,
                editNumber: false,
                lastAction: false,
                contactStatus: false,
                selectedContactStatus: '',
                isMounted: false,
                webinarPop: false,
                showContent: false,
                searchField: false,
                hasQuickLink: true,
                tutorialList: false,
                tutorial: false,
                welcomeCoachSection: 1,
                thrive_checkout: 1,
                marketplace: false,
                marketplacePopup: false
            };
        },

        components: {
          PopUpModal,
            ChartWidget,
            StatsWidget,
            ContactHeader,
            LaunchPad,
            BulkActionChangeGender,
            BulkActionSwitchTab,
            BulkActionCountryCode,
            BulkActionMergeContact,
            BulkActionMoveContact,
            BulkActionCelebrateBirthday,
            BulkActionCopyContact,
            BulkActionChangeMemberId,
            BulkActionSms,
            BulkActionAddTag,
            BulkActionRemoveTag,
            BulkActionSendEmail,
            BulkActionStartPipeline,
            BulkActionRemovePipeline,
            BulkActionStartSequence,
            BulkActionStopSequence,
            BulkActionStartJourney,
            BulkActionTerminateJourney,
            BulkActionSendPlaybook,
            BulkActionRestrictPlaybook,
            BulkActionSendTemplate,
            BulkActionSendForm,
            BulkActionRestrictForm,
            BulkActionSendPage,
            BulkActionRestrictPage,
            BulkActionStartProgressTracking,
            BulkActionStopProgressTracking,
            BulkActionAddFile,
            BulkActionStartHabitTracking,
            BulkActionStopHabitTracking,
            BulkActionStartTask,
            BulkActionStopTask,
            ProfileComponent,
            BulkActionSendPortalMessage,
            Field,
            Form,
            ErrorMessage,
            LastAction,
            BulkActionStartWorkout,
            BulkActionStopWorkout,
            BulkActionStartWorkoutPlan,
            BulkActionStopWorkoutPlan,
            MarketplaceComponent
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 2000);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                    }
                }
            },

            contactSaveLoader (loader) {
                if (!loader) {
                    const vm = this;

                    vm.getContacts(vm.params);
                }
            },

            bulkActionWatcher () {
                const vm = this;

                vm.selectAll = false;
                vm.selectedContacts = [];
                vm.getContacts(vm.params);
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getContacts(vm.params);
            },

            contacts (contacts) {
                const vm = this;

                vm.contactList = contacts.data ? JSON.parse(JSON.stringify(contacts.data)) : [];

                if (vm.selectedContact.id) {
                    const selected = contacts.data ? contacts.data.find(contact => contact.id == vm.selectedContact.id) : null;

                    vm.selectedContact = selected ? selected : {};
                }
            },

            lauchPad (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            profile (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactsLoader (loader) {
                const vm = this;

                if (!loader) {
                    vm.isMounted = true;
                }
            },
        },

        computed: {
            ...mapState({
                contacts: state => state.contactModule.contacts,
                statuses: state => state.contactModule.statuses,
                contactsLoader: state => state.contactModule.contactsLoader,
                bulkActions: state => state.contactModule.bulkActions,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                contactSaveLoader: state => state.contactModule.contactSaveLoader,
                statusTabsLoader: state => state.contactModule.statusTabsLoader,
                updateContactLoader: state => state.contactModule.updateContactLoader,
                statusesGroup: state => state.contactModule.statusesGroup,
                tablePreferences: state => state.contactModule.tablePreferences,
                tipsStatus: state => state.commonModule.tipsStatus,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            if (localStorage.getItem('contacts_params')) {
                vm.params = JSON.parse(localStorage.getItem('contacts_params'));

                if (vm.params.search) {
                    vm.searchField = true;
                }
            }

            vm.getStatuses();
            vm.getContacts(vm.params);
            vm.getCustomFields({ active: 1, type: 'all' });
            localStorage.setItem('contact_profile_tab', 0);

            vm.widget = localStorage.getItem('contacts_widget_toggled') ? localStorage.getItem('contacts_widget_toggled') === "true" : 0;
        },

        methods: {
            ...mapActions({
                getContacts: 'contactModule/getContacts',
                getStatuses: 'contactModule/getStatuses',
                bulkDelete: 'contactModule/bulkDelete',
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
                disableConfirmation: 'contactModule/disableConfirmation',
                validateEmails: 'contactModule/validateEmails',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                updateTemperature: 'contactModule/updateTemperature',
                getCustomFields: 'contactModule/getCustomFields',
                updateContact: 'contactModule/updateContact',
                portalAccountActiveSuspend: 'contactModule/portalAccountActiveSuspend',
                resendLogin: 'contactModule/resendLogin',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
                setTipsStatus: 'commonModule/SET_TIPS_STATUS',
            }),

            handleSearch (search, typing, force = false) {
                const vm = this;

                vm.isTyping = typing;
                vm.params.search = search;

                if (force) {
                    vm.getContacts(vm.params);
                }
            },

            scrollFunc (e) {
                const vm = this;
                let z = e.currentTarget;
                let a = z.clientHeight + z.scrollTop;
                let x = z.scrollHeight;

                if (a < x) {
                    vm.scrollStatus = true;
                } else {
                    vm.scrollStatus = false;
                }
            },

            scrolldown (e, el) {
                let x = e.currentTarget.closest('div');
                let elm = x.querySelector(`.${el}`);
                let z = elm.clientHeight + elm.scrollTop;

                if (z < elm.scrollHeight) {
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, elm.scrollHeight);
                } else {
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, 0);
                }
                e.preventDefault();
                e.stopPropagation();
            },

            temperatureOption (e) {
                e.stopPropagation();
                let el = e.currentTarget.querySelector('.row_option');
                let allEl = document.querySelectorAll('table .row_option');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    for(let i = 0; i < allEl.length; i++){
                        allEl[i].classList.remove('active');
                    }
                    el.classList.add('active');
                }
            },

            closeTemperatureOption () {
                let allEl = document.querySelectorAll('table .row_option');

                for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove('active');
                }
            },

            toggleWidget () {
                const vm = this;

                vm.widget = !vm.widget;

                localStorage.setItem('contacts_widget_toggled', vm.widget);
            },

            toggleBulkAction () {
                const vm = this;
                vm.bulkAction = !vm.bulkAction;
                vm.filter     = false;
                vm.statusList = false;
                const perPageFilter = vm.$refs['contact-per-page-filter'];

                if (perPageFilter) {
                    perPageFilter.dropdown = false;
                }
            },

            toggleStatusDropdown () {
                const vm = this;

                vm.statusList = !vm.statusList;
                vm.bulkAction = false;
                vm.filter     = false;
                const perPageFilter = vm.$refs['contact-per-page-filter'];

                if (perPageFilter) {
                    perPageFilter.dropdown = false;
                }
            },

            toggleFilter () {
                const vm = this;

                vm.filter     = !vm.filter;
                vm.bulkAction = false;
                vm.statusList = false;
            },

            closeAllFilters () {
                const vm = this;

                vm.bulkAction = false;
                vm.statusList = false;
                vm.filter     = false;

                const perPageFilter = vm.$refs['contact-per-page-filter'];

                if (perPageFilter) {
                    perPageFilter.dropdown = false;
                }
            },

            closeBulkOption () {
                const vm = this;
                const allParentElement  = document.querySelectorAll('.bulk_action .megamenu ul li');

                vm.bulkAction = false;
                for(let i = 0; i < allParentElement.length; i++){
                    allParentElement[i].classList.remove('show');
                }
            },

            closeTutorialListDropdown () {
                const vm = this;

                vm.tutorialList = false;
            },

            closeStatusDropdown () {
                const vm = this;
                vm.statusList = false;
            },

            toggleLaunchPad (contact, user) {
                const vm = this;
                if (user.email_status == 0 || user.mailgun_email == '') {
                    const options = Helper.swalWarningOptions('Email sending disabled', 'You can access these features but your email sending is currently disabled. Please contact support to restore your email sending capabilities.', 'Ok')
                    Swal.fire(options);
                }
                vm.selectedContact = contact;
                vm.lauchPad = true;
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.contacts.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            switchTab (tab) {
                const vm = this;

                vm.selectedTab      = tab.title;
                vm.params.page      = 1;
                vm.params.type      = tab.value;
                vm.params.search    = '';
                vm.selectAll        = false;
                vm.selectedContacts = [];

                const contectHeader = vm.$refs['contact-header-component'];

                if (contectHeader && contectHeader.filterComponent) {
                    const filterComponent = contectHeader.filterComponent;

                    if (filterComponent && filterComponent.filters && filterComponent.filters.status) {
                        filterComponent.filters.status = tab.value;
                    }

                }

                vm.statusList = false;

                vm.getContacts(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
                vm.params.page      = page;

                vm.getContacts(vm.params);
            },

            refreshContacts (perPage) {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
                vm.params.page      = 1;
                vm.params.per_page  = perPage;

                vm.getContacts(vm.params);
            },

            applyFilter () {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
                vm.params.page      = 1;

                const contectHeader = vm.$refs['contact-header-component'];

                if (contectHeader && contectHeader.filterComponent) {
                    const filterComponent = contectHeader.filterComponent;

                    if (filterComponent.filters && filterComponent.filters.status) {
                        vm.updateContactStatusFilter(filterComponent.filters.status);
                    }

                    if (filterComponent.unopened_email_days && filterComponent.unopened_email_type) {
                        filterComponent.filters.unopened_email_days = filterComponent.unopened_email_days;
                        filterComponent.filters.unopened_email_type = filterComponent.unopened_email_type;
                    }


                    vm.params.filters  = filterComponent.filters;
                }

                vm.filter = false;

                vm.getContacts(vm.params);
            },

            triggerBulkAction (action) {
                const vm = this;
                vm.confirmationEmail = false;

                if (vm.selectedContacts.length) {
                    if (action == 'deleteContactsPrompt') {
                        const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these contacts', 'Delete')
                              options.preConfirm = function() { return vm.bulkDelete({ contacts: vm.selectedContacts, deleted_through: 'Contact Center' }); };

                        Swal.fire(options);
                    } else if(action == 'toggleMergeContactsPanel') {
                        if( vm.selectedContacts.length < 2 || vm.selectedContacts.length > 3) {
                            const options = Helper.swalWarningOptions('Oops!', 'Please select a minimum of 2 or maximum of 3 contacts');

                            Swal.fire(options)
                        } else {
                            vm[action] = true;
                        }
                    } else if (action == 'toggleDeactivateBirthdayPanel') {
                        const options = Helper.swalConfirmOptions('', "You wish to deactivate these birthday celebration.")
                        options.preConfirm = function () {
                            const params = { status: 0, contact_id: vm.selectedContacts.join(','), type: 'birthday' };
                            return vm.enableDisableDeliveryStatus(params).then((result) => {
                                if (result) {
                                    vm.selectAll = false;
                                    vm.selectedContacts = [];
                                    vm.getContacts(vm.params);
                                }
                            });
                        };

                        Swal.fire(options);
                    } else if (action == 'disableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableConfirmationPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail confirmation for the selected contacts.")
                        options.preConfirm = function() { return vm.disableConfirmation({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'requestConfirmationToggle') {
                        vm.confirmationEmail  = true;
                        vm.sendEmailToggle    = true;
                    } else if (action == 'validateEmailPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to Validate E-mail for the selected contacts.")
                        options.preConfirm = function() { return vm.validateEmails({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'enableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 0) };

                        Swal.fire(options);
                    } else if (action == 'activate') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to activate the client portal for selected contacts")
                        options.preConfirm = function () { return vm.handlePortalAccountActiveSuspend('active', 1) };

                        Swal.fire(options);
                    } else if (action == 'suspend') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to suspend the client portal for selected contacts")
                        options.preConfirm = function () { return vm.handlePortalAccountActiveSuspend('suspended', 0) };

                        Swal.fire(options);
                    } else if (action == 'resendLoginDetails') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to resend login the client portal for selected contacts")
                        options.preConfirm = function () { return vm.handleResendLogin({ contacts: vm.selectedContacts } ) };

                        Swal.fire(options);
                    } else {
                        vm[action] = true;
                    }
                } else {
                    Toastr.error('Please select at least one contact to use bulk action!');
                }
            },

            triggerSubmenu (ev) {
                const parentElement = ev.currentTarget;
                const allParentElement  = document.querySelectorAll('.bulk_action .megamenu ul li');

                if (parentElement.classList.contains('show')) {
                    parentElement.classList.remove('show');
                } else {
                    for(let i = 0; i < allParentElement.length; i++){
                        allParentElement[i].classList.remove('show');
                    }

                    parentElement.classList.add('show');
                }
            },

            handleDelivaryStatus (type, status) {
                const vm = this;

                const params = {
                    contacts: vm.selectedContacts,
                    type: type,
                    status: status,
                }

                return vm.enableDisableDeliveryStatus(params).then((result) => {
                    vm.selectAll = false;
                    vm.selectedContacts = [];
                });
            },

            toggleProfile (contact) {
                const vm = this;

                vm.selectedContact = contact;

                vm.$router.push({ name: 'ContactProfile', params: { id: contact.id } });
            },

            updateSelectedContact (contact) {
                const vm = this;

                vm.getContacts(vm.params);
                vm.selectedContact = contact;
            },

            toggleStarred (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let starred = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    starred = 0;
                } else {
                    element.classList.add('active');
                    starred = 1;
                }

                vm.markContactAsStarred({ starred, contact_id }).then((result) => {
                    if (!result) {
                        if (starred == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }
                });
            },

            handleFilterClear () {
                const vm = this;

                const contectHeader = vm.$refs['contact-header-component'];

                if (contectHeader && contectHeader.filterComponent) {
                    const filterComponent = contectHeader.filterComponent;

                    if (filterComponent && filterComponent.resetFilter) {
                        filterComponent.resetFilter();
                        vm.selectedTab = 'Leads';
                    }
                }

                vm.params = {
                                page: 1,
                                per_page: localStorage.getItem('contacts_per_page') ? parseInt(localStorage.getItem('contacts_per_page')) : 5,
                                search: '',
                                order: 'desc',
                                order_by: 'id',
                                type: 'leads',
                            };

                vm.getContacts(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;
                vm.bulkAction       = false;
                vm.statusList       = false;
                vm.selectAll        = false;
                vm.selectedContacts = [];

                const filter = vm.$refs['contact-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            changeTemperature (contact_id, temperature) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', `You want to change temperature to ${temperature}.`)
                option.preConfirm = function() { return vm.updateTemperature({ contact_id, temperature}) };

                Swal.fire(option);
            },

            editContact (el) {
                let parentElm = el.currentTarget.closest('td');
                let childElm1 = parentElm.querySelector('.email_wpr');
                let childElm2 = parentElm.querySelector('.ph_field');

                if(childElm1.classList.contains('hide')) {
                    childElm1.classList.remove('hide')
                    childElm2.classList.add('hide')
                } else {
                    childElm1.classList.add('hide')
                    childElm2.classList.remove('hide')
                }
            },

            handleEditSelectedField (contact, selectedField) {
                const vm = this;

                if (contact.id != vm.form.id) {
                    vm.handleCloseSelectedFields(selectedField);
                }

                vm.form = {
                    id: contact.id ? contact.id : '',
                    name: contact.name ? contact.name : '',
                    email: contact.email ? contact.email : '',
                    number: contact.number ? contact.number : '',
                    address: contact.address ? contact.address : '',
                    gender: contact.gender ? contact.gender : '',
                    business_name: contact.business_name ? contact.business_name : '',
                    business_number: contact.business_number ? contact.business_number : '',
                    birthdate: contact.birthdate ? contact.birthdate : '',
                    last_action: contact.last_action ? contact.last_action : '',
                    temperature: contact.temperature ? contact.temperature : '',
                    timezone: contact.timezone ? contact.timezone : '',
                    source: contact.source ? contact.source : '',
                    state: contact.state ? contact.state : '',
                    status: contact.status ? contact.status : '',
                    zipcode: contact.zipcode ? contact.zipcode : '',
                    country: contact.country ? contact.country : '',
                    city: contact.city ? contact.city : '',
                    custom_field: contact.custom_field ? contact.custom_field : [],
                };

                vm[selectedField] = true;
            },

            handleUpdateContact (selectedField) {
                const vm = this;
                const contactForm = vm.$refs[`contact-edit-form-${selectedField}-${vm.form.id}`]
                const form        = contactForm[0];

                vm.updatedField       = selectedField;
                vm.form.setFieldError = form.setFieldError;

                vm.updateContact(vm.form).then((result) => {
                    if (result) {
                        const index = vm.contactList.findIndex(contact => {
                            return contact.id == vm.form.id;
                        });

                        vm[selectedField]     = false;
                        vm.contactList[index] = result;
                    }

                    vm.updatedField = '';
                    vm.getContacts(vm.params);
                })
            },

            handleCloseSelectedFields(selectedField) {
                const vm = this;

                vm.editEmail      = false;
                vm.editNumber     = false;

                vm[selectedField] = true;
            },

            updateContactStatusFilter (statusValue) {
                const vm   = this;

                let status = {};

                vm.statusesGroup.map(({items}) => {
                    const item = items.find(({value}) => {
                        return value == statusValue;
                    });

                    if (item) {
                        status = item;
                    }
                });

                vm.selectedTab = status.title;
                vm.params.type = status.value;
            },

            toggleLastAction (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.lastAction      = true;
            },

            toggleContactStatus (contact) {
                const vm = this;

                vm.selectedContact       = contact;
                vm.selectedContactStatus = contact.status;
                vm.contactStatus         = true;
            },

            removeTipsStatus () {
                const vm = this;

                if (vm.$cookies.get('thrive_tips')) {
                    let expireDate = moment().add(1, 'day').startOf('day').toDate();

                    vm.$cookies.set('thrive_tips', false, expireDate);
                }

                vm.setTipsStatus(false);
            },

            handleSortContacts (orderBy) {
                const vm = this;

                if (orderBy == 'name') {
                    vm.params.order_by = 'name'
                } else if (orderBy == 'joined') {
                    vm.params.order_by = 'date_created';
                }

                vm.params.order = vm.params.order == 'desc' ? 'asc' : 'desc';
                vm.params.page = 1;

                vm.getContacts(vm.params);
            },

            handlePortalAccountActiveSuspend (type, status ){
                const vm = this;
                const params = {
                    contacts: vm.selectedContacts,
                    type: type,
                    status: status,
                }

                return vm.portalAccountActiveSuspend(params).then((result) => {
                    vm.selectAll = false;
                    vm.selectedContacts = [];
                });
            },

            handleResendLogin (contactsId){
                const vm = this;

                return vm.resendLogin(contactsId);
            },
        },
    }
</script>

<style scoped>
.popup-container {
  display: flex;
  align-items: center;
  height: 250px;
  width: 500px;
}
.popup-container .left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-container .left h2 {
  margin-bottom: 10px;
}
.popup-container .right img {
  width: 200px;
}
.add_btn{
  height: 35px;
  background: #2f7eed;
  border-radius: 4px;
  padding: 0 15px;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 200px;
}
    .dropdown_wpr.megamenu p {
        color: #f2a31d;
    }

    #contact_index{
        max-width: 1300px;
        margin: 0 auto;
    }
    .spinner_wpr {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(2px);
        background: rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
    .actions .scroll_indicator {
        bottom: 10px;
        top: auto;
    }
    .active_filter {
        background-color: #ffc90e;
    }
    .result_wpr.new{
        border: 0;
    }
    .result_wpr.new .actions {
        border: 0;
        border-radius: 0;
        position: relative;
        z-index: 10;
        margin-bottom: 10px;
    }
    .result_wpr.new .actions > ul{
        align-items: center;
        gap: 15px;
    }
    .result_wpr.new .actions > ul li{
        flex: 0 0 auto;
        border: 0;
        background: transparent;
    }
    .result_wpr.new .actions > ul li.optionDrops{
        border-radius: 17px;
        padding: 7px 0;
        display: flex;
        justify-content: space-between;
    }
    .result_wpr.new .actions > ul li.search_area{
        padding: 0 31px 0 0;
        background: #fbfbfb;
        position: relative;
        height: 33px;
        /* margin-right: 10px; */
        margin-left: auto;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        font-size: 11px;
        height: 17px;
        width: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
    }
    .result_wpr.new .actions > ul li.search_area.active input[type=text]{
        padding: 0 15px;
        width: 450px;
    }
    .result_wpr.new .actions > ul li.search_area .search_btn{
        position: absolute;
        right: 0;
        top: 0;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .result_wpr.new .actions > ul li.search_area .search_btn i{
        margin: 0;
    }
    .result_wpr.new .actions > ul li.contacts-tabs .dropdown_wpr{
        right: 1px;
        left: auto;
    }
    .result_wpr.new .actions > ul li .checkbox i{
        margin-left: 0;
        color: #121525;
    }
    .result_wpr.new .actions > ul li .checkbox h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding-left: 12px;
    }
    .table_footer ul{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 0 0;
    }
    .table_footer ul li{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #999;

    }
    .table_footer ul li.sort_list{
        padding: 7px 15px;
        border-radius: 20px;
        cursor: pointer;
        border: 1px solid #e9e9e9;
        background: #fff;
        color: #5a5a5a;
        position: relative;
        font-size: 11px;
        line-height: 14px;
    }
    .table_footer ul li.sort_list i{
        padding-left: 5px;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr), .contacts-tabs :deep(.dropdown_wpr){
        border-radius: 6px;
        overflow: hidden;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr ul){
        height: 80px;
        overflow: overlay;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr ul::-webkit-scrollbar){
        width: 3px;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr ul::-webkit-scrollbar-thumb){
        background: #dbdbdb;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr ul li){
        border: 0;
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
    }
    .table_footer ul li.sort_list :deep(.dropdown_wpr ul li.active){
        background: #f5f5f5;
    }
    .contacts-tabs :deep(.dropdown_wpr ul li.dropdown-header){
        background: #e9e9e9;
    }
    .user_history .welcome_text{
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        text-align: left;
        padding-bottom: 5px;
    }
    .user_history .subtext{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        padding-left: 10px;
    }
    .tracking_list {
        display: flex;
        gap: 30px;
        margin: 15px 0;
    }
    .tracking_list li {
        flex: 1;
        margin: 0;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 8px;
        padding: 15px 20px;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tracking_list li h4{
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .tracking_list li h3 {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        display: block;
        color: #121525;
        padding-top: 10px;
    }
    .quick_link{
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        padding: 25px 20px;
        margin: 20px 0 40px 0;
        position: relative;
    }
    .close_btn{
        position: absolute;
        right: 15px;
        top: 15px;
        color: #8d8d8d;
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .quick_link .sub_header{
        margin: 0 0 20px 0;
        justify-content: center;
    }
    .quick_link ul{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-around;
    }
    .quick_link li{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 0 33.333%;
        padding: 15px;
    }
    .circle{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #f6faff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .quick_link li img, .circle img{
        height: 35px;
        width: auto;
    }
    .quick_link li h3{
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: #121525;
        margin: 10px 0 7px 0;
    }
    .quick_link li p{
        font-size: 11px;
        line-height: 17px;
        font-weight: 400;
        color: #5a5a5a;
        margin-bottom: 20px;
    }
    .quick_link li a{
        text-decoration: none;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        border: 1px solid #d9d9d9;
        padding: 5px 15px;
        border-radius: 15px;
        display: inline-block;
    }
    .quick_link .call_out{
        position:absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        font-family: 'Architects Daughter', cursive;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #121525;
        width: 100px;
    }
    .quick_link .call_out img{
        position: absolute;
        top: 90%;
        left: 0;
        width: 40px;
        transform: scaleX(-1) rotate(-20deg);
    }
    .modal.webinar_modal .modal_container{
        background-color: #fff;
    }
    .modal.webinar_modal h5{
        font-size: 14px;
        line-height: 23px;
        color: #121525;
    }
    .modal.webinar_modal a{
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        padding: 7px 25px;
        border-radius: 17px;
        display: inline-block;
        margin: 15px 0;
    }
    .modal.webinar_modal .close_btn {
        background: transparent;
        border: 1px solid #5a5a5a;
        font-size: 12px;
        color: #5a5a5a;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        cursor: pointer;
    }
    .panel_wpr{
        align-items: center;
    }
    .show_hide{
        font-size: 11px;
        line-height: 14px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 7px 15px;
        border-radius: 15px;
        margin-top: 0px;
    }

    a.access-portal {
        text-decoration: none;
        color: #2F7FED;
    }

    .featured_items{
        margin-bottom: 40px;
    }
    .featured_items ul{
        display: flex;
        justify-content: center;
        margin: 0 -15px;
    }
    .featured_items ul li{
        padding: 15px;
    }
    .featured_items ul li.get_started{
        width: 50%;
    }
    .featured_items ul li.anonymous_card{
        width: 50%;
    }
    .featured_items .feature_card{
        border-radius: 8px;
        background: #fff;
        padding: 25px 30px;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .featured_items .feature_card .left{
        width: 70%;
        text-align: left;
    }
    .featured_items .feature_card .right{
        width: 30%;
        padding-top: 35px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .featured_items .feature_card .right .switch_option{
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .featured_items .feature_card:hover .right .switch_option{
        opacity: 1;
    }
    /* .featured_items .feature_card h1{
        font-size: 30px;
        line-height: 40px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    } */
    .featured_items .feature_card h2{
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }
    /* .featured_items .feature_card h4{
        max-width: 95%;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #5a5a5a;
    } */
    .featured_items .feature_card h5{
        max-width: 95%;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .featured_items .feature_card .redirect_btn{
        display: block;
        text-decoration: none;
        height: 35px;
        border-radius: 4px;
        color: #2f7eed;
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        margin-top: 20px;
        cursor: pointer;
    }
    .featured_items .feature_card .redirect_btn i{
        padding-left: 10px;
    }
    .featured_items .feature_card img{
        margin: auto 0 auto auto;
        max-height: 110px;
        width: auto;
    }
    .result_wpr table td label.checkbox span{
        display: none;
        transition: all 0.3s ease-in-out;
    }
    .result_wpr table td label.checkbox.show span, .result_wpr table tr:hover td label.checkbox span{
        display: flex;
    }

    @media(max-width: 1399px){
        .featured_items .feature_card{
            padding: 20px 30px;
        }
        .featured_items .feature_card img{
            max-height: 120px;
        }
        /* .featured_items .feature_card h1{
            font-size: 24px;
            line-height: 32px;
        } */
        .featured_items .feature_card h2{
            font-size: 18px;
            line-height: 25px;
        }
        /* .featured_items .feature_card h4{
            font-size: 14px;
            line-height: 21px;
        } */
        .featured_items .feature_card h5{
            font-size: 13px;
            line-height: 19px;
        }
        .featured_items .feature_card button{
            font-size: 14px;
            line-height: 20px;
        }
        .featured_items .feature_card .redirect_btn{
            height: 30px;
            font-size: 15px;
            line-height: 20px;
        }
    }

    @media(max-width: 1199px){
        .featured_items ul li.get_started, .featured_items ul li.anonymous_card{
            width: 50%;
        }
        /* .featured_items .feature_card h1{
            font-size: 18px;
            line-height: 25px;
        } */
        /* .featured_items .feature_card h4{
            font-size: 13px;
            line-height: 19px;
        } */
        .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            width: 260px;
        }
    }
    @media(max-width: 767px){
        .featured_items ul{
            flex-wrap: wrap;
        }
        .featured_items ul li.get_started, .featured_items ul li.anonymous_card{
            width: 100%;
        }
        .quick_link li{
            flex: 0 0 100%;
            padding-bottom: 30px;
        }
        :deep(.section_header ul){
            width: 100%;
        }
        .result_wpr.new .actions > ul li.search_area{
            order: -1;
            position: absolute;
            right: 0;
            bottom: 100%;
            margin-bottom: 6px;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            margin-left: auto;
        }
    }
    @media(max-width: 550px){
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            position: relative;
            bottom: none;
            margin-bottom: 0;
            padding-left: 10px !important;
        }
        .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            padding: 0;
        }
    }
    @media(max-width:499px){
        .tracking_list{
            gap: 20px;
            flex-wrap: wrap;
            flex-direction: row;
        }
        .tracking_list li{
            flex: 1 0 calc(50% - 10px);
        }

    }
    .sort-contact i {
        padding-left: 10px;
        color: #6b6767;
        display: none;
    }
    .sort-contact:hover i {
        display: initial;
    }

    table.contact-table tr td:last-child {
        text-align: right;
    }
    .empty_box{
       padding: 30px 0; 
    }
    .empty_box img{
        max-height: 90px !important;
        width: auto;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .empty_box h4{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
    }

    .result_wpr.new .actions > ul li.search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #e9e9e9;
        position: relative;
        margin-left: 20px;
    }

    .result_wpr.new .actions > ul li.search_area input[type=text] {
        font-size: 11px;
        height: 35px;
        width: 250px;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }

    .result_wpr table td .user_img {
        min-width: 40px;
    }

    .result_wpr table td .user_wpr h4 {
        width: auto;
    }
</style>
