<template>
    <div class="modal add_clients" v-if="modelValue">
        <div class="modal_container add_types" >
            <h2><button class="close_btn" @click="closeModal"><i class="fas fa-times"></i></button></h2>
            <slot />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
  modelValue: Boolean,
                          })
const emits = defineEmits(['update:modelValue']);

const closeModal = () => emits('update:modelValue', false)
</script>

<style scoped>
    .close_btn {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
</style>
